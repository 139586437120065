import React, { useRef, useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import Layout from '../components/layout';
import SEO from "../components/seo";
import { isValidEmail, addAndRemoveDisabledCls } from '../utils/utils';

import "../styles/style-contact.css";
// init("wJVS8NI9A6FKL8obZ");

const IPChecker = () => {
    const seo = { metaDesc: 'Patents, Trademarks, Copyrights, Trade Secrets, Provisional Patent, Utility Patent, USPTO' };
    //
    const captchaRef = useRef(null)
    //
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [emailSent, setEmailSent] = useState(false);

    const submit = async (e) => {
        e.preventDefault();
        const token = captchaRef.current.getValue();
        if (!token) {
            alert('Please Confirm You Are Not a Robot!');
        } else {
            // check 
            const response = await fetch("https://api.trustyip.com/recaptcha/verify?token=" + token);
            const responseJson = await response.json();
            if ((responseJson.data.success) || (responseJson.data['error-codes'][0] === 'timeout-or-duplicate')) {
                if (name && email && message) {
                    // validate email
                    if (!isValidEmail(email)) {
                        alert('Email is invalid!!!');
                        return;
                    }
                    // call api
                    const data = {
                        email: email,
                        name: name,
                        phone: '',
                        subject: '[PatentPC] New message from ' + email,
                        message: message,
                        site: 'patentpc.com'
                    }
                    
                    // send
                    fetch("https://api.trustyip.com/subcribers/add", {
                    "method": "POST",
                    "headers": {
                        "content-type": "application/json",
                        "accept": "application/json"
                    },
                    "body": JSON.stringify(data)
                    })
                    .then(response => response.json())
                    .then(response => {
                        alert('Thank you for contacting us. We will feedback to you soon!!!');
                        // reset
                        setName('');
                        setEmail('');
                        setMessage('');
                        setEmailSent(true);
                    })
                    .catch(err => {
                        alert('There is something wrong. Please try again later!');
                        console.log(err);
                    });

                    // reset
                    captchaRef.current.reset();
                    //
                    addAndRemoveDisabledCls('contactBtn');
                } else {
                    alert('Please fill in all fields.');
                }
            } else {
                alert('Please Confirm You Are Not a Robot!');
            }
        }
    }
    return (
        <Layout>
            <SEO title="IPChecker - PatentPC"  canonical='/ipchecker' seo={seo} />
            <div className="container ipchecker-vh" >
                {/* <div class="overlay"></div> */}
                {/* <img src="/assets/images/ipchecker-banner.png" alt=""/> */}
                <div className='row'>
                    <div className='col-md-12'>
                    <h1 className='h1-title'>Does Your Business<br/> Have Protectable<br/> <strong>IP ASSETS?</strong></h1>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <p className='sub-text'>Your competitors can steal your intellectual
                        <br/>properties. Learn which of your business assets<br/> can and should be protected</p>
                    </div>
                </div>
                
            </div>
            {/* <div class="container py-5"><h2 class="page-title">IPChecker</h2></div> */}

            <div className='container mb-5 py-5' style={{ backgroundColor: '#f9fafb'}}>
                <div className='col-lg-12'>
                    <p>
                        IPChecker identifies coverage and gaps in protecting intellectual property assets
                        and make sure that your business captures and perfects ownership of strategic IP assets 
                        that are strategic to your venture and the audit can be useful in the following scenarios:
                    </p>
                    <p>
                    <ul>
                            <li>Before you execute your Go-To-Market plan to avoid copying</li>
                            <li>Before working with investors, partners, acquirers or lenders</li>
                            <li>When setting a business strategy</li>
                            <li>Monetizing via licensing or enforcement/litigation</li>
                        </ul>
                    </p>
                    <p>
                        Best of all, it is free and easy to run using public information such as the information on your website.
                        Try it in
                    </p>
                    <p className='case-s-items'>
                        <a className='orange-btn' href="https://ipcheck.patentpc.com/register" target='_blank'>Try IPChecker</a>
                    </p>
                </div>
            </div>
        </Layout>
    );
};

export default IPChecker;
